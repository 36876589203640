import { ComponentsPropsList } from '@mui/material/styles/props';

export interface Localization {
    components?: {
        MuiAlert?: {
            defaultProps: Pick<ComponentsPropsList['MuiAlert'], 'closeText'>;
        };
        MuiBreadcrumbs?: { defaultProps: Pick<ComponentsPropsList['MuiBreadcrumbs'], 'expandText'> };
        MuiTablePagination?: {
            defaultProps: Pick<
                ComponentsPropsList['MuiTablePagination'],
                'labelRowsPerPage' | 'labelDisplayedRows' | 'getItemAriaLabel'
            >;
        };
        MuiRating?: {
            defaultProps: Pick<ComponentsPropsList['MuiRating'], 'emptyLabelText' | 'getLabelText'>;
        };
        MuiAutocomplete?: {
            defaultProps: Pick<
                ComponentsPropsList['MuiAutocomplete'],
                'clearText' | 'closeText' | 'loadingText' | 'noOptionsText' | 'openText'
            >;
        };
        // The core package has no dependencies on the @mui/lab components.
        // We can't use ComponentsPropsList, we have to duplicate and inline the definitions.
        MuiPagination?: {
            defaultProps: Pick<ComponentsPropsList['MuiPagination'], 'aria-label' | 'getItemAriaLabel'>;
        };
    };
}

export const enUS: Localization = {
    /*
  components: {
    MuiBreadcrumbs: { defaultProps: {
      expandText: 'Show path',
    }},
    MuiTablePagination: { defaultProps: {
      getItemAriaLabel: (type) => {
        if (type === 'first') {
          return 'Go to first page';
        }
        if (type === 'last') {
          return 'Go to last page';
        }
        if (type === 'next') {
          return 'Go to next page';
        }
        // if (type === 'previous') {
        return 'Go to previous page';
      },
      labelRowsPerPage: 'Rows per page:',
      labelDisplayedRows: ({ from, to, count }) =>
  `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`,
    }},
    MuiRating: { defaultProps: {
      getLabelText: value => `${value} Star${value !== 1 ? 's' : ''}`,
      emptyLabelText: 'Empty',
    }},
    MuiAutocomplete: { defaultProps: {
      clearText: 'Clear',
      closeText: 'Close',
      loadingText: 'Loading…',
      noOptionsText: 'No options',
      openText: 'Open',
    }},
    MuiAlert: { defaultProps: {
      closeText: 'Close',
    }},
    MuiPagination: {  defaultProps: {
      'aria-label': 'Pagination navigation',
      getItemAriaLabel: (type, page, selected) => {
        if (type === 'page') {
          return `${selected ? '' : 'Go to '}page ${page}`;
        }
        if (type === 'first') {
          return 'Go to first page';
        }
        if (type === 'last') {
          return 'Go to last page';
        }
        if (type === 'next') {
          return 'Go to next page';
        }
        // if (type === 'previous') {
        return 'Go to previous page';
      },
    }},
  },
*/
};

export const ltLT: Localization = {
    components: {
        MuiBreadcrumbs: {
            defaultProps: {
                expandText: 'Rodyti kelią',
            },
        },
        MuiTablePagination: {
            defaultProps: {
                getItemAriaLabel: type => {
                    if (type === 'first') {
                        return 'Grįžti į pirmą puslapį';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį puslapį';
                    }
                    if (type === 'next') {
                        return 'Eiti į sekantį puslapį';
                    }
                    // if (type === 'previous') {
                    return 'Grįžti į praeitą puslapį';
                },
                labelRowsPerPage: 'Eilučių per puslapį:',
                labelDisplayedRows: ({ from, to, count }) =>
                    `${from}–${to} iš ${count !== -1 ? count : `daugiau nei ${to}`}`,
            },
        },
        MuiRating: {
            defaultProps: {
                getLabelText: value => `${value} Žvaigždutė${value !== 1 ? 's' : ''}`,
                emptyLabelText: 'Tuščia',
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                clearText: 'Išvalyti',
                closeText: 'Uždaryti',
                loadingText: 'Kraunama…',
                noOptionsText: 'No options',
                openText: 'Nėra rezultatų',
            },
        },
        MuiAlert: {
            defaultProps: {
                closeText: 'Uždaryti',
            },
        },
        MuiPagination: {
            defaultProps: {
                'aria-label': 'Puslapiavimo navigacija',
                getItemAriaLabel: (type, page, selected) => {
                    if (type === 'page') {
                        return `${selected ? '' : 'Eitį į '}puslapį ${page}`;
                    }
                    if (type === 'first') {
                        return 'Grįžti į pirmą puslapį';
                    }
                    if (type === 'last') {
                        return 'Eiti į paskutinį puslapį';
                    }
                    if (type === 'next') {
                        return 'Eiti į sekantį puslapį';
                    }
                    // if (type === 'previous') {
                    return 'Grįžti į praeitą puslapį';
                },
            },
        },
    },
};
