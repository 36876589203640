import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import closeIcon from '@iconify/icons-material-symbols/close-rounded';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Checkbox, FormControlLabel, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import MIconButton from 'components/@material-extend/MIconButton/MIconButton';
import { login } from 'external/firebase/controllers/auth';
import useLocales from 'hooks/useLocales';
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths';

export interface LoginUserFormValues {
    email: string;
    password: string;
    remember: boolean;
}

const LoginForm: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const { translate } = useLocales();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .email(translate('email_address_must_be_valid'))
            .matches(
                /(@codeacademy.lt|@codeacademylt.onmicrosoft.com|@codeacademykids.com)$/,
                translate('email_address_must_be_from_codeacademy_domain'),
            )
            .required(translate('email_address_required')),
        password: Yup.string().required(translate('password_required')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true,
        },
        validationSchema: LoginSchema,
        onSubmit: async (
            values: LoginUserFormValues,
            { setSubmitting, setStatus, resetForm }: FormikHelpers<LoginUserFormValues>,
        ) => {
            const res = await login(values.email, values.password);
            setSubmitting(false);
            if (!res.ok) {
                resetForm();
                setStatus(translate(res.code));
                return;
            }
            navigate(PATH_DASHBOARD.general.modules, { replace: true });
            enqueueSnackbar(translate('login_success'), {
                variant: 'success',
                // eslint-disable-next-line react/display-name
                action: key => (
                    // @ts-ignore
                    <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeIcon} />
                    </MIconButton>
                ),
            });
        },
    });

    const { errors, status, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword(show => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {status && <Alert severity='error'>{status}</Alert>}

                    <TextField
                        fullWidth
                        autoComplete='username'
                        type='email'
                        label={translate('email_address')}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete='current-password'
                        type={showPassword ? 'text' : 'password'}
                        label={translate('password')}
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <IconButton onClick={handleShowPassword} edge='end'>
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>

                <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ my: 2 }}>
                    <FormControlLabel
                        control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
                        label={translate('remember_me')}
                    />

                    <Link component={RouterLink} variant='subtitle2' to={PATH_AUTH.resetPassword}>
                        {translate('forgot_password')}?
                    </Link>
                </Stack>

                <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
                    {translate('login')}
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
};

export default LoginForm;
