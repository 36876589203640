import { Avatar, useTheme } from '@mui/material';
import React, { forwardRef } from 'react';

// MAvatar.propTypes = {
//   children: PropTypes.node,
//   sx: PropTypes.object,
//   color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error'])
// };

// TODO: Add typings
// @ts-ignore
// eslint-disable-next-line react/display-name
const MAvatar = forwardRef(({ color = 'default', sx, children, ...other }, ref) => {
    const theme = useTheme();

    if (color === 'default') {
        return (
            // @ts-ignore
            <Avatar ref={ref} sx={sx} {...other}>
                {children}
            </Avatar>
        );
    }

    return (
        // @ts-ignore
        <Avatar
            ref={ref}
            sx={{
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette[color].contrastText,
                backgroundColor: theme.palette[color].main,
                ...sx,
            }}
            {...other}
        >
            {children}
        </Avatar>
    );
});

export default MAvatar;
