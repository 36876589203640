import groupIcon from '@iconify/icons-material-symbols/groups-rounded';
import modulesIcon from '@iconify/icons-material-symbols/menu-book-rounded';
import settingsIcon from '@iconify/icons-material-symbols/settings-rounded';
import { Icon } from '@iconify/react';
import React from 'react';

import { PATH_DASHBOARD } from 'routes/paths';

const sidebarConfig = [
    {
        subheader: '',
        items: [
            {
                title: 'modules',
                path: PATH_DASHBOARD.general.modules,
                icon: <Icon icon={modulesIcon} width='48' height='48' />,
            },
            {
                title: 'settings',
                path: PATH_DASHBOARD.user.root,
                icon: <Icon icon={settingsIcon} width='48' height='48' />,
            },
            {
                title: 'groups',
                accessibleRoles: ['admin'],
                path: PATH_DASHBOARD.groups.root,
                icon: <Icon icon={groupIcon} width='48' height='48' />,
            },
        ],
    },
];

export default sidebarConfig;
