const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', 'O', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

const getFirstCharacter = (name: string) => name.charAt(0).toUpperCase();

const getAvatarColor = (name: string) => {
    const firstNameCharacter = getFirstCharacter(name);
    if (PRIMARY_NAME.includes(firstNameCharacter)) return 'primary';
    if (INFO_NAME.includes(firstNameCharacter)) return 'info';
    if (SUCCESS_NAME.includes(firstNameCharacter)) return 'success';
    if (WARNING_NAME.includes(firstNameCharacter)) return 'warning';
    if (ERROR_NAME.includes(firstNameCharacter)) return 'warning';
    return 'default';
};

const createAvatar = (name: string) => ({
    name: getFirstCharacter(name),
    color: getAvatarColor(name),
});

export default createAvatar;
