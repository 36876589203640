export default function Button(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '50px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    '&:hover': {
                        boxShadow: 'none',
                    },
                },
                sizeLarge: {
                    height: 48,
                },
                containedInherit: {
                    color: theme.palette.grey[800],
                    '&:hover': {
                        backgroundColor: theme.palette.grey[400],
                    },
                },
                outlinedInherit: {
                    border: `1px solid ${theme.palette.grey[500_32]}`,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
                textInherit: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                },
            },
        },
    };
}
