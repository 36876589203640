import { Card, Container, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import loginIllustrataion from 'assets/login_illustration.svg';
import MHidden from 'components/@material-extend/MHidden/MHidden';
import LoginForm from 'components/auth/Login/LoginForm';
import Page from 'components/Page/Page';
import useLocales from 'hooks/useLocales';
import AuthLayout from 'layouts/AuthLayout';
import { PATH_AUTH } from 'routes/paths';

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
    const { translate } = useLocales();

    return (
        <RootStyle title={`${translate('login')} | CodeAcademy Online`}>
            <AuthLayout />

            <MHidden width='mdDown'>
                <SectionStyle>
                    <img src={loginIllustrataion} alt={translate('login_illustration')} />
                </SectionStyle>
            </MHidden>

            <Container maxWidth='sm'>
                <ContentStyle>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant='h4' gutterBottom>
                            {translate('login_form_title')}
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{translate('login_form_subtitle')}</Typography>
                    </Stack>

                    <LoginForm />

                    <Typography variant='body2' align='center' sx={{ mt: 3 }}>
                        {translate('dont_have_account')} &nbsp;
                        <Link to={PATH_AUTH.register} variant='subtitle2' component={RouterLink}>
                            {translate('link_to_registration_text')}
                        </Link>
                    </Typography>
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}
