import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { matchPath, NavLink as RouterLink, useLocation } from 'react-router-dom';

import useLocales from 'hooks/useLocales';

// ----------------------------------------------------------------------
// @ts-ignore
const ListSubheaderStyle = styled(props => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    color: theme.palette.text.primary,
}));

// @ts-ignore
const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
        top: 0,
        right: 0,
        width: 3,
        bottom: 0,
        content: "''",
        display: 'none',
        position: 'absolute',
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        backgroundColor: theme.palette.primary.main,
    },
}));

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

// ----------------------------------------------------------------------

export function NavButton({ item, isShow, handleClick }) {
    const { translate } = useLocales();
    const { title, icon, info } = item;

    return (
        <ListItemStyle onClick={handleClick}>
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            {isShow && (
                <>
                    <ListItemText disableTypography primary={translate(title)} />
                    {info && info}
                </>
            )}
        </ListItemStyle>
    );
}

// TODO: Add typings
export function NavItem({ item, isShow }) {
    const theme = useTheme();
    const { translate } = useLocales();
    const { pathname } = useLocation();
    const { title, path, icon, info, children } = item;
    const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen(!open);
    };

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        '&:before': { display: 'block' },
    };

    const activeSubStyle = {
        color: 'text.primary',
        fontWeight: 'fontWeightMedium',
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

                    {isShow && (
                        <>
                            <ListItemText disableTypography primary={translate(title)} />
                            {info && info}
                            <Box
                                component={Icon}
                                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                                sx={{ width: 16, height: 16, ml: 1 }}
                            />
                        </>
                    )}
                </ListItemStyle>

                {isShow && (
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding>
                            {children.map(item => {
                                const { title, path } = item;
                                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                                return (
                                    <ListItemStyle
                                        key={title}
                                        // @ts-ignore
                                        component={RouterLink}
                                        to={path}
                                        sx={{
                                            ...(isActiveSub && activeSubStyle),
                                        }}
                                    >
                                        <ListItemIconStyle>
                                            <Box
                                                component='span'
                                                sx={{
                                                    width: 4,
                                                    height: 4,
                                                    display: 'flex',
                                                    borderRadius: '50%',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    bgcolor: 'text.disabled',
                                                    transition: theme => theme.transitions.create('transform'),
                                                    ...(isActiveSub && {
                                                        transform: 'scale(2)',
                                                        bgcolor: 'primary.main',
                                                    }),
                                                }}
                                            />
                                        </ListItemIconStyle>
                                        <ListItemText disableTypography primary={translate(title)} />
                                    </ListItemStyle>
                                );
                            })}
                        </List>
                    </Collapse>
                )}
            </>
        );
    }

    return (
        <ListItemStyle
            // @ts-ignore
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            {isShow && (
                <>
                    <ListItemText disableTypography primary={translate(title)} />
                    {info && info}
                </>
            )}
        </ListItemStyle>
    );
}

// TODO: Add typings
const NavSection = ({ navConfig, isShow = true, group, ...props }) => {
    const { translate } = useLocales();

    return (
        <Box {...props}>
            {navConfig.map(list => {
                const { subheader, items } = list;
                return (
                    <List key={subheader}>
                        {isShow && <ListSubheaderStyle>{translate(subheader)}</ListSubheaderStyle>}
                        {items.map(
                            item =>
                                (!item.accessibleRoles || item.accessibleRoles.includes(group)) && (
                                    // @ts-ignore
                                    <NavItem key={item.title} item={item} isShow={isShow} />
                                ),
                        )}
                    </List>
                );
            })}
        </Box>
    );
};

export default NavSection;
