import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from 'App';
import { AuthProvider } from 'contexts/AuthContext';
import reportWebVitals from 'reportWebVitals';

import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';

// i18n
import './locales/i18n';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    // Suppresses 'HelmetProvider' cannot be used as a JSX component error
    // @ts-ignore
    <HelmetProvider>
        <BrowserRouter>
            <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CollapseDrawerProvider>
                        <App />
                    </CollapseDrawerProvider>
                </LocalizationProvider>
            </AuthProvider>
        </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
