import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { createContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';

import { PATH_DASHBOARD } from 'routes/paths';

const initialState = {
    isCollapsed: false,
};

const CollapseDrawerContext = createContext(initialState);

const CollapseDrawerProvider: React.FunctionComponent = ({ children }) => {
    const theme = useTheme();
    const isLessonRoute = !!useMatch(`${PATH_DASHBOARD.general.modules}/:module/:lesson`);
    const isLessonTopicRoute = !!useMatch(`${PATH_DASHBOARD.general.modules}/:module/:lesson/:topic`);
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const [isCollapsed, setIsCollapsed] = useState(initialState.isCollapsed);

    useEffect(() => {
        setIsCollapsed(!isMobile && (isLessonRoute || isLessonTopicRoute));
    }, [isLessonRoute, isLessonTopicRoute, isMobile]);

    return (
        <CollapseDrawerContext.Provider
            value={{
                isCollapsed,
            }}
        >
            {children}
        </CollapseDrawerContext.Provider>
    );
};

export { CollapseDrawerProvider, CollapseDrawerContext };
