import { Alert, AlertTitle, Container } from '@mui/material';
import React from 'react';

import useAuth from 'hooks/useAuth';

interface RoleGuardProps {
    accessibleRoles: string[];
}

const RoleGuard: React.FunctionComponent<RoleGuardProps> = ({ accessibleRoles, children }) => {
    const { profile } = useAuth();

    if (!accessibleRoles.includes(profile?.group)) {
        return (
            <Container>
                <Alert severity='error'>
                    <AlertTitle>Permission Denied</AlertTitle>
                    You do not have permission to access this page
                </Alert>
            </Container>
        );
    }

    return <>{children}</>;
};

export default RoleGuard;
