import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

import { firebaseConfig } from './config';

const app = initializeApp(firebaseConfig);

export const auth = getAuth();
export const db = getFirestore();
export const functions = getFunctions(undefined, 'europe-west1');
export const analytics = getAnalytics(app);
