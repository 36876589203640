import { CssBaseline, ThemeOptions } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React, { useMemo } from 'react';

import useLocales from 'hooks/useLocales';

import componentsOverride from './overrides';
import palette from './palette';
import shadows, { customShadows } from './shadows';
import shape from './shape';
import typography from './typography';

export default function ThemeConfig({ children }) {
    const { currentLang } = useLocales();

    const themeOptions: ThemeOptions = useMemo(
        () => ({
            palette,
            shape,
            typography,
            shadows,
            customShadows,
        }),
        [],
    );

    const themeWithLocale = React.useMemo(() => createTheme(themeOptions, currentLang), [currentLang, themeOptions]);
    themeWithLocale.components = componentsOverride(themeWithLocale);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeWithLocale}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
