import { Box } from '@mui/material';
import React from 'react';

// import { motion } from 'framer-motion';
import { varMediumClick, varSmallClick } from './variants';

// ----------------------------------------------------------------------

// ButtonAnimate.propTypes = {
//   mediumClick: PropTypes.bool,
//   children: PropTypes.node,
//   sx: PropTypes.object
// };

// TODO: Add props
const ButtonAnimate = ({ mediumClick = false, children, sx, ...other }) => (
    // @ts-ignore
    <Box
        // component={motion.div}
        whileTap='tap'
        whileHover='hover'
        variants={mediumClick ? varMediumClick : varSmallClick}
        sx={{ display: 'inline-flex', ...sx }}
        {...other}
    >
        {children}
    </Box>
);

export default ButtonAnimate;
