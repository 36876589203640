import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import { varWrapEnter } from './variants';

interface MotionContainerProps {
    open: boolean;
    initial?: string;
}

const MotionContainer: React.FunctionComponent<MotionContainerProps> = ({ open, children, ...other }) => {
    return (
        <Box
            component={motion.div}
            initial={false}
            animate={open ? 'animate' : 'exit'}
            variants={varWrapEnter}
            {...other}
        >
            {children}
        </Box>
    );
};

export default MotionContainer;
