import { enUS, ltLT } from './theme/locale';

// LAYOUT
// ----------------------------------------------------------------------

export const DRAWER_WIDTH = 280;
export const COLLAPSE_WIDTH = 102;

export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 90;

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------
export const defaultSettings = {
    themeMode: 'light',
};

export const allLangs = [
    {
        label: 'English',
        value: 'en',
        systemValue: enUS,
        icon: '/assets/icons/flags/ic_flag_en.svg',
    },
    {
        label: 'Lietuvių',
        value: 'lt',
        systemValue: ltLT,
        icon: '/assets/icons/flags/ic_flag_lt.svg',
    },
];

export const defaultLang = allLangs[1]; // Lithuanian set as default lang
