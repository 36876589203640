import { LocaleStrings } from './i18n';

const lt: LocaleStrings = {
    login_success: 'Prisijungta sėkmingai',
    password_reset_code_verified: 'Slaptažodžio nustatymo iš naujo kodas patvirtintas',
    password_reset_success: 'Slaptažodis sėkmingai nustatytas iš naujo',
    password_update_success: 'Slaptažodis sėkmingai atnaujintas',
    register_success: 'Užsiregistravote sėkmingai',
    user_update_success: 'Vartotojo profilis sėkmingai atnaujintas',
    all: 'Visos',
    allowed: 'Leidžiama',
    already_have_account: 'Jau turite paskyrą?',
    and: 'ir',
    back: 'atgal',
    by_registering_agree: 'Registruodamiesi patvirtiname, kad sutinkate su CodeAcademy Online',
    change_password: 'pakeisti slaptažodį',
    code: 'Kodas',
    code_required: 'Kodas yra privalomas',
    confirm_new_password: 'Patvirtinkite naują slaptažodį',
    content: 'Turinys',
    dashboard: 'Pagrindinis',
    didnt_receive_email: 'Negavote el. laiško?',
    dont_have_account: `Neturite paskyros?`,
    edit: 'Redaguoti',
    email_address: 'El. pašto adresas',
    email_address_must_be_from_codeacademy_domain: 'Turi būti galiojantis CodeAcademy el. pašto adresas',
    email_address_must_be_valid: 'El. pašto adresas turi būti validus',
    email_address_required: 'El. pašto adresas yra privalomas',
    enter_new_password_subtitle: 'Įveskite naują slaptažodį, kuris bus susietas su jūsų paskyra.',
    enter_new_password_title: 'Įveskite naują slaptažodį',
    error_fetching_lessons: 'Gaunant pamokas įvyko klaida.',
    error_fetching_modules: 'Gaunant modulius įvyko klaida',
    error_fetching_topic_content: 'Gaunant temos turinį įvyko klaida',
    error_fetching_topics: 'Gaunant temą įvyko klaida',
    finish: 'Užbaigti pamoką',
    first_name: 'Vardas',
    first_name_required: 'Vardas yra privalomas',
    forgot_password: 'Pamiršote slaptažodį',
    forgot_your_password_details:
        'Įveskite su paskyra susietą el. pašto adresą ir el. paštu atsiųsime nuorodą slaptažodžiui nustatyti iš naujo.',
    forgot_your_password_title: 'Pamiršote slaptažodį?',
    general: 'bendri',
    go_to_home: 'Grįžti į pagrindinį',
    group: 'Grupė',
    group_content: 'Grupės turinys',
    groups: 'Grupės',
    groups_content: 'grupės turinį',
    groups_content_updates: 'Grupės turinys atnaujintas',
    home: 'Pagrindinis',
    incorrect_or_tampered_link: 'Neteisinga arba sugadinta nuoroda',
    internal_server_error: 'Vidinė serverio klaida',
    internal_server_error_description:
        'Įvyko klaida. Bandykite dar kartą vėliau arba susisiekite su sistemos administratoriumi.',
    last_name: 'Pavardė',
    last_name_required: 'Pavardė yra privaloma',
    latest: 'Naujausia',
    lesson: 'Pamoka',
    lessons: 'Pamokos',
    link_to_login_text: 'Prisijungti',
    link_to_registration_text: 'Užsiregistruokite',
    login: `Prisijungti`,
    login_form_subtitle: `Įveskite savo prisijungimo duomenis žemiau.`,
    login_form_title: 'Prisijunkite prie CodeAcademy Online',
    login_greeting_title: `Sveiki sugrįžę!`,
    login_illustration: 'prisijungimo iliustracija',
    login_with_google: `Prisijungti su Google`,
    logout: `Atsijungti`,
    module_code: 'Modulio kodas',
    module_illustration: 'modulio iliustracija',
    modules: 'Moduliai',
    name: 'Pavadinimas',
    new_password: 'Naujas slaptažodis',
    new_password_helper_text: 'Slaptažodis turi būti bent 8 simbolių',
    new_password_required: 'Reikalingas naujas slaptažodis',
    next: 'Sekanti tema',
    no_lessons_found: 'Nerasta jokių pamokų',
    no_modules_found: 'Nerasta jokių modulių',
    no_results_for: 'Rezultatų nerasta',
    no_results_helper_text: 'Pabandykite patikrinti ar nėra rašybos klaidų, arba naudokite pilnus žodžius.',
    no_topics_found: 'Nerasta jokių temų',
    not_found: 'Rezultatų nerasta',
    notifications: 'pranešimai',
    old_password: 'Senas slaptažodis',
    old_password_required: 'Reikalingas senas slaptažodis',
    open_lessons_helper_text: 'Tuščia reikšmė reiškia, kad modulis yra nematomas',
    opened_lessons: 'Atidarytos pamokos',
    page_not_found: 'Puslapis nerastas',
    page_not_found_description: 'Atsiprašome, nepavyko rasti puslapio, kurio ieškote. Galbūt neteisingai įvedėte URL?',
    password: 'Slaptažodis',
    password_must_be_at_least_8_characters: 'Slaptažodis turėtų būti per 8 simbolių',
    password_must_match: 'Slaptažodžiai turi sutapti',
    password_required: 'Slaptažodis yra privalomas',
    please_check_your_email: 'Prašome patikrinti savo el. paštą.',
    please_try_again_or_contact_admin: '',
    privacy_policy: 'Privatumo Politika',
    redirecting: 'Nukreipiama',
    register: `Registruotis`,
    register_illustration: 'registracijos iliustracija',
    registration_form_subtitle: 'Išskirtinai skirta CodeAcademy ir CodeAcademy College studentams.',
    registration_form_title: 'Užsiregistruokite prie CodeAcademy Online',
    registration_greeting_title: `Internetinė švietimo platforma, skirta padėti mūsų studentams`,
    remember_me: 'Prisiminti mane',
    confirm_email: 'Patvirkinkite savo el. pašto adresą',
    resend_email: 'Iš naujo siųsti el. laišką',
    reset_password: 'Atstatyti slaptažodį',
    reset_password_greeting_title: 'Atstatykite prisijungimą',
    reset_password_illustration: 'slaptažodžio nustatymo iš naujo iliustracija',
    save_changes: 'Išsaugoti pakeitimus',
    search_groups: 'Ieškoti grupių',
    sent_confirmation_email_to: 'Išsiuntėme patvirtinimo el. laišką adresu',
    set_password: 'Nustatyti slaptažodį',
    something_went_wrong: 'Įvyko klaida.',
    sorted_ascending: 'rūšiuojama didėjančia tvarka',
    sorted_descending: 'rūšiuojama mažėjančia tvarka',
    terms_of_service: 'Paslaugų Teikimo Sąlygomis',
    too_long: 'Per ilgas!',
    too_short: 'Per trumpas!',
    topic: 'Tema',
    topics: 'Temos',
    unable_to_logout: `Nepavyko atsijungti!`,
    update_photo: 'Atnaujinti nuotrauką photo',
    upload_photo: 'Įkelti nuotrauką',
    user: 'Vartotojas',
    user_management: 'Vartotojo valdymas',
    verify: 'Patvirtinti',
    verify_code_subtitle: 'Įveskite 6 simbolių patvirtinimo kodą, kuris buvo bendrinamas su jūsų grupe',
    verify_code_title: 'Įveskite grupės kodą',
    verify_email_and_code_illustration: 'patvirtinkite el. pašto adresą ir kodo iliustracija',
    verify_email_subtitle_part_1: 'Išsiuntėme Jums patvirtinimo nuorodą',
    verify_email_subtitle_part_2: 'spustelėkite šią nuorodą, kad patvirtintumėte savo el. pašto adresą.',
    verify_email_title: 'Patikrinkite savo el. paštą!',
    view_lesson: 'Žiūrėti pamoką',
    view_module: 'Žiūrėti modulį',
    your_account: 'Jūsų paskyra',
    lastest_lesson: 'Naujausia pamoka',
    all_lessons: 'Visos pamokos',
    open_lessons: 'atidaryti pamokas',
    settings: 'nustatymai',
    language: 'kalba',
    language_preference: 'Kalbos nuostatos',
    language_preference_saved: 'Kalbos pasirinkimas išsaugotas',
    language_required: 'Kalbos pasirinkimas yra privalomas',
    'auth/email-already-in-use': 'Pateiktą el. paštą jau naudoja esamas vartotojas',
    'auth/expired-action-code': 'Nuorodos galiojimo laikas baigėsi',
    'auth/invalid-action-code': 'Nuoroda sugadinta arba jau buvo panaudota anksčiau',
    'auth/invalid-email': 'Pateiktas el. pašto adresas neteisingas',
    'auth/invalid-user-token': 'Naudotojo prieigos raktas neteisingas',
    'auth/user-disabled': 'Vartotojas yra užblokuotas',
    'auth/user-not-found': 'Vartotojas nerastas. Jeigu jungiatės pirmą kartą, užsiregistruokite.',
    'auth/user-token-expired': 'Naudotojo prieigos rakto galiojimo laikas baigėsi',
    'auth/weak-password': 'Slaptažodis per silpnas',
    'auth/wrong-password': 'Neteisingi prisijungimo duomenys',
    'auth/invalid-group-code': 'Neteisingas grupės kodas',
    'db/no-document': 'Įrašų nerasta',
    last_updated: 'Paskautinį kartą atnaujinta',
    by: '|',
};

export default lt;
