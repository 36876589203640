import React from 'react';

import LoadingScreen, { ProgressBarStyle } from 'components/LoadingScreen/LoadingScreen';
import NotistackProvider from 'components/NotistackProvider/NotistackProvider';
import useAuth from 'hooks/useAuth';
import Router from 'routes';
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';

import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const App: React.FunctionComponent = () => {
    const { isInitialized } = useAuth();

    return (
        <ThemeConfig>
            <NotistackProvider>
                <GlobalStyles />
                <ProgressBarStyle />
                <ScrollToTop />
                {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
        </ThemeConfig>
    );
};

export default App;
