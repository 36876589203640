import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { AppBar, IconButton, Toolbar } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React from 'react';

import MHidden from 'components/@material-extend/MHidden/MHidden';

import { APP_BAR_DESKTOP, APP_BAR_MOBILE, COLLAPSE_WIDTH, DRAWER_WIDTH } from 'config';
import useCollapseDrawer from 'hooks/useCollapseDrawer';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APP_BAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APP_BAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}));

// ----------------------------------------------------------------------

interface DashboardNavbarProps {
    onOpenSidebar: () => void;
}

const DashboardNavbar: React.FunctionComponent<DashboardNavbarProps> = ({ onOpenSidebar }) => {
    const { isCollapsed } = useCollapseDrawer();

    return (
        <RootStyle
            sx={{
                ...(isCollapsed && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
                }),
            }}
        >
            <ToolbarStyle>
                <MHidden width='lgUp'>
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MHidden>
            </ToolbarStyle>
        </RootStyle>
    );
};

export default DashboardNavbar;
