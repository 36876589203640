import React, { lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import RoleGuard from 'guards/RoleGuard';
import DashboardLayout from 'layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';

// eslint-disable-next-line react/display-name
const Loadable = Component => props => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
    const isDashboard = pathname.includes('/dashboard');

    return (
        <Suspense
            fallback={
                <LoadingScreen
                    sx={{
                        ...(!isDashboard && {
                            top: 0,
                            left: 0,
                            width: 1,
                            zIndex: 9999,
                            position: 'fixed',
                        }),
                    }}
                />
            }
        >
            <Component {...props} />
        </Suspense>
    );
};

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('pages/auth/Login/Login')));
const Register = Loadable(lazy(() => import('pages/auth/Register/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword/ResetPassword')));
const VerifyEmailAndCode = Loadable(lazy(() => import('pages/auth/VerifyEmailAndCode/VerifyEmailAndCode')));
const UserManagement = Loadable(lazy(() => import('pages/auth/UserManagement/UserManagement')));

// Dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard/Dashboard')));
const Module = Loadable(lazy(() => import('pages/dashboard/Module')));
const Lesson = Loadable(lazy(() => import('pages/dashboard/Lesson')));

// Management
const User = Loadable(lazy(() => import('pages/dashboard/User')));
const Groups = Loadable(lazy(() => import('pages/dashboard/Groups')));
const GroupContent = Loadable(lazy(() => import('pages/dashboard/GroupContent')));

// Misc
const Page404 = Loadable(lazy(() => import('pages/Page404')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));

export default function Router() {
    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'reset-password',
                    element: (
                        <GuestGuard>
                            <ResetPassword />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'verify',
                    element: (
                        <GuestGuard>
                            <VerifyEmailAndCode />
                        </GuestGuard>
                    ),
                },
                { path: 'user-management', element: <UserManagement /> },
            ],
        },
        {
            path: 'dashboard',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { index: true, element: <Navigate to='/dashboard/modules' replace /> },
                {
                    path: 'modules',
                    element: <Dashboard />,
                },
                {
                    path: 'modules/:module',
                    element: <Module />,
                },
                {
                    path: 'modules/:module/:lesson',
                    children: [
                        { index: true, element: <Lesson /> },
                        { path: ':topic', element: <Lesson /> },
                    ],
                },
                {
                    path: 'user',
                    element: <User />,
                },
                {
                    path: 'groups',
                    element: (
                        <RoleGuard accessibleRoles={['admin']}>
                            <Groups />
                        </RoleGuard>
                    ),
                },
                {
                    path: 'groups/:group/content',
                    element: (
                        <RoleGuard accessibleRoles={['admin']}>
                            <GroupContent />
                        </RoleGuard>
                    ),
                },
            ],
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [{ index: true, element: <Navigate to='/dashboard' replace /> }],
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                // { path: 'maintenance', element: <Maintenance /> },
                { path: '404', element: <Page404 /> },
                { path: '500', element: <Page500 /> },
                { path: '*', element: <Navigate to='/404' replace /> },
            ],
        },
        { path: '*', element: <Navigate to='/404' replace /> },
    ]);
}
