import { IconButton } from '@mui/material';
import React, { forwardRef } from 'react';

import ButtonAnimate from 'components/animate/ButtonAnimate';

// MIconButton.propTypes = {
//   children: PropTypes.node
// };

// TODO: Add typings
// eslint-disable-next-line react/display-name
const MIconButton = forwardRef(({ children, ...other }, ref) => (
    // @ts-ignore
    <ButtonAnimate>
        {/* @ts-ignore */}
        <IconButton ref={ref} {...other}>
            {children}
        </IconButton>
    </ButtonAnimate>
));

export default MIconButton;
