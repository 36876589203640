const path = (root: string, sublink: string) => {
    return `${root}${sublink}`;
};

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
    verify: path(ROOTS_AUTH, '/verify'),
    userManagement: path(ROOTS_AUTH, '/user-management'),
};

export const PATH_PAGE = {
    page404: '/404',
    page500: '/500',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        modules: path(ROOTS_DASHBOARD, '/modules'),
    },
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
    },
    groups: {
        root: path(ROOTS_DASHBOARD, '/groups'),
        groupContent: path(ROOTS_DASHBOARD, '/groups/:group/content'),
    },
};
