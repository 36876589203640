import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box, GlobalStyles } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import React from 'react';

const SnackbarStyles = () => {
    const theme = useTheme();

    return (
        <GlobalStyles
            styles={{
                '#root': {
                    '& .SnackbarContent-root': {
                        width: '100%',
                        padding: theme.spacing(1.5),
                        margin: theme.spacing(0.25, 0),
                        // @ts-ignore
                        boxShadow: theme.customShadows.z8,
                        borderRadius: theme.shape.borderRadius,
                        color: theme.palette.grey[0],
                        backgroundColor: theme.palette.grey[900],
                        // eslint-disable-next-line max-len
                        '&.SnackbarItem-variantSuccess, &.SnackbarItem-variantError, &.SnackbarItem-variantWarning, &.SnackbarItem-variantInfo':
                            {
                                color: theme.palette.text.primary,
                                backgroundColor: theme.palette.background.paper,
                            },
                    },
                    '& .SnackbarItem-message': {
                        padding: '0 !important',
                        fontWeight: theme.typography.fontWeightMedium,
                    },
                    '& .SnackbarItem-action': {
                        marginRight: 0,
                        color: theme.palette.action.active,
                        '& svg': { width: 20, height: 20 },
                    },
                },
            }}
        />
    );
};

interface SnackbarIconProps {
    icon: IconifyIcon;
    color: string;
}

const SnackbarIcon: React.FunctionComponent<SnackbarIconProps> = ({ icon, color }) => {
    return (
        <Box
            component='span'
            sx={{
                mr: 1.5,
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1.5,
                alignItems: 'center',
                justifyContent: 'center',
                color: `${color}.main`,
                bgcolor: theme => alpha(theme.palette[color].main, 0.16),
            }}
        >
            <Icon icon={icon} width={24} height={24} />
        </Box>
    );
};

const NotistackProvider: React.FunctionComponent = ({ children }) => {
    return (
        <>
            <SnackbarStyles />

            {/* @ts-ignore */}
            <SnackbarProvider
                dense
                maxSnack={5}
                preventDuplicate
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                iconVariant={{
                    success: <SnackbarIcon icon={checkmarkCircle2Fill} color='success' />,
                    error: <SnackbarIcon icon={infoFill} color='error' />,
                    warning: <SnackbarIcon icon={alertTriangleFill} color='warning' />,
                    info: <SnackbarIcon icon={alertCircleFill} color='info' />,
                }}
            >
                {children}
            </SnackbarProvider>
        </>
    );
};

export default NotistackProvider;
