import { Color } from '@mui/material';
import { alpha, PaletteColor } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

export const GREY: Color = {
    50: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    A100: alpha('#919EAB', 0.08),
    A200: alpha('#919EAB', 0.16),
    A400: alpha('#919EAB', 0.32),
    A700: alpha('#919EAB', 0.56),
};

export const PRIMARY: PaletteColor = {
    light: '#4437FF',
    main: '#1200FF',
    dark: '#5749FE',
    contrastText: '#FFF',
};
export const SECONDARY: PaletteColor = {
    light: '#FF37EE',
    main: '#FF00E9',
    dark: '#C800B8',
    contrastText: '#fff',
};
export const INFO: PaletteColor = {
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    contrastText: '#FFF',
};
export const SUCCESS: PaletteColor = {
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    contrastText: GREY[800],
};
export const WARNING: PaletteColor = {
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    contrastText: GREY[800],
};
export const ERROR: PaletteColor = {
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    contrastText: '#fff',
};

const palette: PaletteOptions = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    divider: GREY.A400,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff' },
    action: {
        active: GREY[600],
        hover: GREY.A100,
        hoverOpacity: 0.08,
        selected: GREY.A200,
        disabled: GREY.A700,
        disabledOpacity: 0.48,
        disabledBackground: GREY.A200,
        focus: GREY.A200,
    },
};

export default palette;
