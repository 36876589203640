import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';

interface MHiddenProps {
    width: 'xsDown' | 'smDown' | 'mdDown' | 'lgDown' | 'xlDown' | 'xsUp' | 'smUp' | 'mdUp' | 'lgUp' | 'xlUp';
    children: React.ReactElement;
}

const MHidden: React.FunctionComponent<MHiddenProps> = ({ width, children }) => {
    const breakpoint = width.substring(0, 2);

    // @ts-ignore
    const hiddenUp = useMediaQuery((theme: Theme) => theme.breakpoints.up(breakpoint));
    // @ts-ignore
    const hiddenDown = useMediaQuery((theme: Theme) => theme.breakpoints.down(breakpoint));

    if (width.includes('Down')) {
        return hiddenDown ? null : children;
    }

    if (width.includes('Up')) {
        return hiddenUp ? null : children;
    }

    return null;
};

export default MHidden;
