import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from 'config';

import DashboardNavbar from './navbar/DashboardNavbar';
import DashboardSidebar from './navbar/DashboardSidebar';
import FabButtonAnimate from '../../components/animate/FabButtonAnimate';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

const DashboardLayout: React.FunctionComponent = () => {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    const handleOpenWidgetClick = () => {
        // @ts-ignore
        OpenWidget.call('maximize');
    };

    return (
        <RootStyle>
            {isTablet && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle
                sx={{
                    transition: theme.transitions.create('margin', {
                        duration: theme.transitions.duration.complex,
                    }),
                }}
            >
                <Outlet />
                <FabButtonAnimate
                    onClick={handleOpenWidgetClick}
                    color='secondary'
                    aria-label='chat'
                    size='large'
                    style={{ position: 'fixed', bottom: 16, right: 16 }}
                >
                    <Icon icon='mdi:robot' width={28} height={28} />
                </FabButtonAnimate>
            </MainStyle>
        </RootStyle>
    );
};

export default DashboardLayout;
