import { Box } from '@mui/material';
import React from 'react';

import logoIconOnly from 'assets/logo-icon-only.svg';
import logo from 'assets/logo.svg';

// TODO: Add typings
const Logo = ({ sx = {}, iconOnly = false }) => (
    <Box component='img' src={iconOnly ? logoIconOnly : logo} sx={{ height: 42, ...sx }} />
);

export default Logo;
