import { useTranslation } from 'react-i18next';

import { allLangs, defaultLang } from 'config';
import en from 'locales/en';

// ----------------------------------------------------------------------

export default function useLocales() {
    const { i18n, t: translate } = useTranslation();
    const langStorage = localStorage.getItem('i18nextLng');

    const currentLang = allLangs.find(_lang => _lang.value === langStorage) || defaultLang;

    const handleChangeLanguage = (newlang: string) => {
        i18n.changeLanguage(newlang);
    };

    return {
        onChangeLang: handleChangeLanguage,
        translate: (text: keyof typeof en, options?: any) => translate(text, options),
        currentLang,
        allLangs,
    };
}
