import {
    applyActionCode,
    confirmPasswordReset,
    createUserWithEmailAndPassword,
    EmailAuthProvider,
    getIdToken,
    reauthenticateWithCredential,
    sendEmailVerification,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    updateProfile,
    verifyPasswordResetCode,
} from 'firebase/auth';
import { doc, setDoc, UpdateData, updateDoc } from 'firebase/firestore';

import { auth, db } from 'external/firebase/index';

import { getGroupIdByGroupCode } from './db';
import { formatErrorMessage } from './utils';

export const login = async (email: string, password: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const sendConfirmationEmail = async () => {
    if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
    }
};

export const register = async (email: string, password: string, firstName: string, lastName: string) => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const docRef = doc(db, 'users', userCredential.user.uid);
        await setDoc(docRef, {
            email: userCredential.user.email,
            firstName,
            lastName,
        });
        await updateProfile(userCredential.user, {
            displayName: `${firstName} ${lastName}`,
        });
        await sendConfirmationEmail();
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const updateUser = async (firstName: string, lastName: string, photoURL: string) => {
    try {
        if (!auth.currentUser) {
            throw new Error('auth/no-user-found');
        }
        const docRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(docRef, { firstName, lastName } as UpdateData<never>);
        await updateProfile(auth.currentUser, {
            displayName: `${firstName} ${lastName}`,
            photoURL,
        });
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const updateCredentials = async (oldPassword: string, newPassword: string) => {
    try {
        if (!auth.currentUser || !auth.currentUser.email) {
            throw new Error('auth/no-user-found');
        }
        const emailCredential = EmailAuthProvider.credential(auth.currentUser.email, oldPassword);
        await reauthenticateWithCredential(auth.currentUser, emailCredential);
        await updatePassword(auth.currentUser, newPassword);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const setUserGroup = async (groupCode: string) => {
    try {
        if (!auth.currentUser) {
            throw new Error('auth/no-user-found');
        }
        const groupId = await getGroupIdByGroupCode(groupCode);
        if (groupId?.error) {
            throw new Error(groupId.error);
        }
        if (!groupId) {
            throw new Error('auth/invalid-group-code');
        }
        const docRef = doc(db, 'users', auth.currentUser.uid);
        await updateDoc(docRef, {
            group: groupId,
        });
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const logout = async () => {
    await signOut(auth);
};

export const refreshToken = async () => {
    if (auth.currentUser) {
        await getIdToken(auth.currentUser, true);
    }
};

export const resetPassword = async (email: string) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const checkPasswordResetCode = async actionCode => {
    try {
        await verifyPasswordResetCode(auth, actionCode);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const handleResetPassword = async (actionCode, newPassword) => {
    try {
        await confirmPasswordReset(auth, actionCode, newPassword);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};

export const handleVerifyEmail = async actionCode => {
    try {
        await applyActionCode(auth, actionCode);
        return { ok: true } as { ok: true };
    } catch (error) {
        return formatErrorMessage(error);
    }
};
