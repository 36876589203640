import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';

import Logo from 'components/Logo/Logo';

import LanguagePopover from './dashboard/header/LanguagePopover';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}));

// ----------------------------------------------------------------------

const AuthLayout: React.FunctionComponent = () => {
    return (
        <HeaderStyle>
            <Link to='/'>
                <Logo />
            </Link>

            <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
                <LanguagePopover />
            </Stack>
        </HeaderStyle>
    );
};

export default AuthLayout;
