import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import { PATH_DASHBOARD } from 'routes/paths';

const GuestGuard: React.FunctionComponent = ({ children }) => {
    const { isAuthenticated, isVerified } = useAuth();

    if (isAuthenticated && isVerified) {
        return <Navigate to={PATH_DASHBOARD.root} />;
    }

    return <>{children}</>;
};

export default GuestGuard;
