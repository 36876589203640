import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import Login from 'pages/auth/Login/Login';
import { PATH_AUTH } from 'routes/paths';

const AuthGuard: React.FunctionComponent = ({ children }) => {
    const { isAuthenticated, isVerified } = useAuth();
    const { pathname } = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return <Login />;
    }

    if (!isVerified) {
        return <Navigate to={PATH_AUTH.verify} replace />;
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return <Navigate to={requestedLocation} />;
    }

    return <>{children}</>;
};

export default AuthGuard;
