import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { PageProps } from './Page.types';

// eslint-disable-next-line react/display-name
const Page: React.FunctionComponent<PageProps> = forwardRef(({ children, title, ...other }, ref) => (
    <Box ref={ref} {...other}>
        {/* @ts-ignore */}
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </Box>
));

export default Page;
