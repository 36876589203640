import closeIcon from '@iconify/icons-material-symbols/close-rounded';
import logoutIcon from '@iconify/icons-material-symbols/logout-rounded';
import { Icon } from '@iconify/react';
import { Box, Drawer, Link, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import MHidden from 'components/@material-extend/MHidden/MHidden';
import MIconButton from 'components/@material-extend/MIconButton/MIconButton';
import Logo from 'components/Logo/Logo';
import MyAvatar from 'components/MyAvatar/MyAvatar';
import NavSection, { NavButton } from 'components/NavSection/NavSection';
import { logout } from 'external/firebase/controllers/auth';
import useAuth from 'hooks/useAuth';
import useCollapseDrawer from 'hooks/useCollapseDrawer';
import useLocales from 'hooks/useLocales';
import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths';

import sidebarConfig from './config';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex,
        }),
    },
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey.A200,
}));

// ----------------------------------------------------------------------

interface DashboardSidebarProps {
    isOpenSidebar: boolean;
    onCloseSidebar: () => void;
}

const DashboardSidebar: React.FunctionComponent<DashboardSidebarProps> = ({ isOpenSidebar, onCloseSidebar }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { translate } = useLocales();
    const { user, profile } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { isCollapsed } = useCollapseDrawer();

    const userName = user?.displayName || `${profile?.firstName} ${profile?.lastName}` || '';

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/');
        } catch (error) {
            enqueueSnackbar(translate('unable_to_logout'), {
                variant: 'error',
                // eslint-disable-next-line react/display-name
                action: key => (
                    // @ts-ignore
                    <MIconButton size='small' onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeIcon} />
                    </MIconButton>
                ),
            });
        }
    };

    const renderContent = (
        <>
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapsed && {
                        alignItems: 'center',
                    }),
                }}
            >
                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                    <Box component={RouterLink} to={PATH_DASHBOARD.root} sx={{ display: 'inline-flex' }}>
                        <Logo iconOnly={isCollapsed} />
                    </Box>
                </Stack>

                {isCollapsed ? (
                    <MyAvatar sx={{ mx: 'auto', mb: 2 }} />
                ) : (
                    <Link underline='none' component={RouterLink} to={PATH_DASHBOARD.user.root}>
                        <AccountStyle>
                            <MyAvatar />
                            <Box sx={{ ml: 2 }}>
                                <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                                    {userName}
                                </Typography>
                                <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                    {/* @ts-ignore */}
                                    {profile?.group}
                                </Typography>
                            </Box>
                        </AccountStyle>
                    </Link>
                )}
            </Stack>

            <NavSection navConfig={sidebarConfig} isShow={!isCollapsed} group={profile?.group} />

            <Box sx={{ flexGrow: 1 }} />
            <Stack
                spacing={3}
                sx={{
                    pb: 2,
                }}
                direction='row'
                alignItems='center'
                justifyContent='space-between'
            >
                <NavButton
                    item={{
                        title: 'logout',
                        path: PATH_AUTH.login,
                        icon: <Icon icon={logoutIcon} width='48' height='48' />,
                    }}
                    isShow={!isCollapsed}
                    handleClick={handleLogout}
                />
            </Stack>
        </>
    );

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapsed ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                },
            }}
        >
            <MHidden width='lgUp'>
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>

            <MHidden width='lgDown'>
                <Drawer
                    open
                    variant='persistent'
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(isCollapsed && {
                                width: COLLAPSE_WIDTH,
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MHidden>
        </RootStyle>
    );
};

export default DashboardSidebar;
