import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React from 'react';

import MAvatar from 'components/@material-extend/MAvatar/MAvatar';
import useAuth from 'hooks/useAuth';
import createAvatar from 'utils/createAvatar';

interface MyAvatarProps {
    sx?: SxProps<Theme>;
}

const MyAvatar: React.FunctionComponent<MyAvatarProps> = props => {
    const { user, profile } = useAuth();
    const userName = user?.displayName || `${profile?.firstName} ${profile?.lastName}` || '';
    const avatar = createAvatar(userName);

    return (
        // @ts-ignore
        <MAvatar src={user?.photoURL} alt={userName} color={user?.photoURL ? 'default' : avatar.color} {...props}>
            {avatar.name}
        </MAvatar>
    );
};

export default MyAvatar;
