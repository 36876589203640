import enLocales from 'locales/en';

export const formatErrorMessage = (error: any): { ok: false; code: keyof typeof enLocales } => {
    if (error?.code) {
        return { ok: false, code: error.code };
    }
    if (error?.message) {
        return { ok: false, code: error.message };
    }
    return {
        ok: false,
        code: 'something_went_wrong',
    };
};

export const chunkArray = (array: any[], size: number): any[][] => {
    const result: any[][] = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};
